.blog_div {
  display: inline-block;
}

.blog_img_div {
  position: relative;
  height: 200px;
  border-radius: 20px 20px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.blog_label {
  position: absolute;
  left: 0;
  top: 30px;
  background: #1764d8;
  border-radius: 0px 10px 10px 0px;
  color: #fff;
  padding: 10px 15px;
}

.blog_text {
  padding: 50px;
}
.blog_text h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #7b7b7b;
}
.blog_link {
  float: right;
  color: white;
  background: #1764d8;
  border-radius: 20px;
  padding: 8px 29px;
  transition: all 0.2s linear;
  line-height: 0px;
}
.blog_link:hover{
    color: white;
    padding: 8px 20px 8px 38px;
}
