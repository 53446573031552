.landing_banner {
  min-height: 500px;
  background: #1d1d1f;
  padding-top: 90px;
  padding-left: 100px;
}

.landing_banner_h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 58.6199px;
  line-height: 71px;
}

.landing_banner_text_bold {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 30px;
}

.landing_banner_text {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 30px;
  max-width: 700px;
  margin-bottom: 50px;
}

@media screen and (max-width: 600px){
  .landing_banner {
    background: #1d1d1f;
    min-height: 500px;
    padding: 30px;
}

}

@media screen and (max-width: 768px){
.landing_banner_h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}
}