.card {
  background: #ffffff;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  /* min-height: 500px; */
}

.box_shadow{
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
}