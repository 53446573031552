.btn_primary {
  background: #1764d8;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fff;
  cursor: pointer;
  padding: 10px 50px;
}

.btn_white{
  background: #fff;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color:  #1764d8;
  cursor: pointer;

  padding: 10px 50px;
}