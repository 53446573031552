.your_work_companion_section {
  background: #d9d7d7;
  margin: 150px 0;
}

.your_work_companion_text {
  max-width: 600px;
    margin-left: 40%;
  padding: 30px 0;
  position: relative;
}

.your_work_companion_text h2 {
  color: #1d1d1f;
  margin-bottom: 1.5rem;
  font-size: 32px;
}

.your_work_companion_text p {
  margin-bottom: 1.5rem;
  
}

.your_work_companion_text button {
  margin-right: 1.5rem;
}

.your_work_companion_text img{
  position: absolute;
  height: 550px;
  top: 50%;
  transform: translateY(-50%);
  left: -40%;
  margin-top: -30px;
  margin-left: -77px;
}

@media screen and (max-width: 768px){
.your_work_companion_text {
    margin-left: 0;
    max-width: 100%;
    padding: 30px;
    position: relative;
    text-align: center;
}
.your_work_companion_text img {
  height: 400px;
  left: auto;
  position: relative;
  top: auto;
  -webkit-transform: none;
  -webkit-transform: initial;
  transform: none;
}
.your_work_companion_text button {
  display: block;
  margin: auto;
}
}