.navbar__hamburger,
.navbar__mobile {
  display: none;
}

.navbar__div {
  position: sticky;
  top: 0px;
  background-color: #1d1d1f;
  color: #ffffff;
  display: flex;
  padding: 1rem 7rem;
  align-items: center;
  align-content: space-around;
  max-height: 121px;
  z-index: 999;
}

.navbar__div__logo img {
  height: 36px;
}

.navbar__div__links {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navbar__div__links_ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: auto;
}
.navbar__div__link {
  margin: 0 2rem;
  font-size: 17px;
  font-weight: 600px;
}

.nav_barlink_dropdown {
  position: relative;
}

.navbar__div__link_dropdown_icon {
  position: absolute;
  font-size: 13px;
  display: inline-block;
  bottom: 2px;
  right: -15px;
}

.navbar__div__links_buttons button {
  background: transparent;
  color: #ffffff;
  margin: 0 10px;
  border: 0px;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
}

.navbar__div__links_buttons button:last-child {
  background-color: #fff;
  color: #1764d8;
  padding: 10px 40px;
  border-radius: 20px;
}
.navbar__div__link__dropdown_div {
  position: absolute;
  display: flex;
  background-color: #fff;
  padding: 1rem 2rem;
  left: 0;
  /* width: 100%; */
  top: 54px;
  box-shadow: 0px 0px 3px 0 #00000059;
  z-index: 99999;
  color: #000;
}

.navbar__div__link__dropdown_div__items {
  display: flex;
}
.navbar__div__link__dropdown_div__items > ul {
  list-style: none;
}
.navbar__div__link__dropdown_div__items ul li {
  width: auto;
}

.productDropdown {
  position: absolute;
  top: 72px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
}

.navbar_backdrop{
  position: absolute;
  top: 100px;
  left: 0px;
  padding: 100px;
  width: 100%;
  background-color: #f5f5f7;
  height: 100vh;
  opacity: 0.5;
}

.single_product_desc{
  display: flex;
  color: #000;
}

.product_icon{
  padding: 10px 20px;
  font-size: 40px;
}

.product_text {
  color: #7B7B7B !important;
}

.product_text h4 {
  color: #7B7B7B !important;
}

@media screen and (max-width: 768px) {
.navbar__div {
  position: sticky;
  top: 0px;
  background-color: #1d1d1f;
  color: #ffffff;
  display: flex;
  padding: 15px;
  align-items: center;
  align-content: space-around;
  max-height: 121px;
  z-index: 999;
}

}

@media screen and (max-width: 1024px) {
  .navbar__hamburger {
    display: block;
    margin-left: auto;
    font-size: 20px;
  }
  .navbar__div__links {
    display: none;
  }
  .navbar__mobile {
    background: #1d1d1f;
      display: block;
      height: 0;
      left: 0;
      overflow: hidden;
      padding: 0 4rem;
      position: absolute;
      top: 65px;
      transition: .5s ease;
      width: 100%;
      z-index: 9999;
  }

  .navbar__mobile ul {
    list-style: none;
  }
  .navbar__div ul li {
    margin: 20px 0;
  }
}


.borderRadiusNavbarDropdown{
  border-radius: 0 20px 20px 0;
}

.slide_from_top {
  -webkit-animation: slide_from_topAn 2s ease-in-out;
  animation: slide_from_topAn 2s ease-in-out;
  display: block;
  height: auto!important;
}