.pricingCard {
  padding: 100px 55px;
  max-width: 460px;
  position: relative;
}

.pricingCard h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #1d1d1f;
}

.pricingCard h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
  color: #1764d8;
}

.pricingCard ul {
  list-style: none;
}

.pricingCard ul li {
  margin-bottom: 1rem;
  font-size: 20px;
}

.pricing_badge {
  position: absolute;
  left: 0px;
  top: 32px;
  color: white;
  padding: 12px 17px;
  background: #1764d8;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
}
.pricingCard button {
  display: block;
  margin-bottom: 70px;
}
