.landing_nav {
  width: 100%;
  height: 108px;
  top: 0px;
  background: #000000;
  display: flex;
  align-items: center;
  text-align: right;
  flex: 1 1;
  padding: 0 110px;
  color: white;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}

.landing_nav div {
  flex: 50%;
}

.landing_nav__button {
  background: #ffffff;
  border-radius: 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  padding: 10px 40px;
  color: #1764d8;
  float: right;
}
