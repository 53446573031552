* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.d-none {
  display: none;
}

.border-bottom-1 {
  border-bottom: 1px solid #696969;
}
.border-bottom-white {
  border-bottom: 1px solid #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* color: #454545; */
  color: #1d1d1f;
  font-weight: 600;
}

h2 {
  font-size: 32px;
  line-height: 39px;
}
h4 {
  font-size: 20px;
  line-height: 24px;
}

.text_div_404 {
  min-height: calc(100vh - 104px);
}

.ul_404 {
  text-align: center;
  padding-top: 66px;
  justify-content: center;
  display: flex;
  margin: auto;
  color: white;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;

  list-style: none;
}

.ul_404 li {
  margin-right: 40px;
  padding-bottom: 5px;
}
.ul_404 li:hover {
  border-bottom: 1px solid #fff;
}
button {
  border: 0px;
}
.pt-60px {
  padding-top: 60px;
}
.line-height-30 {
  line-height: 30px;
}
.font-size-20 {
  font-size: 20px !important;
}
.color-brown {
  color: #e5794c !important;
}

.color-yellow {
  color: #ecc054 !important;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}
.section_bottom_margin {
  margin-bottom: 60px;
}
body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f7;
  font-family: "Inter";
  font-style: normal;
  line-height: 19px;
  color: #6f6f6f;
}

.p-200 {
  padding: 200px;
}

.p-25 {
  padding: 25px;
}

.px-200 {
  padding-left: 200px;
  padding-right: 200px;
}
.px-50px {
  padding-left: 50px;
  padding-right: 50px;
}

.py-50px {
  padding-top: 50px;
  padding-bottom: 50px;
}
.py-100px {
  padding-top: 100px;
  padding-bottom: 100px;
}
.btn-primary {
  background: #1764d8;
  border: 1px solid #1764d8;
  border-radius: 6.32504px;
  height: 54.82px;
  color: #fff;
  padding: 15px 20px;
}

.m-auto {
  margin: auto;
}
.m-2 {
  margin: 1rem;
}
.py-40px {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-100px {
  padding-top: 100px;
  padding-bottom: 100px;
}
.w-45{
  width: 45%;
}

.fw-500 {
  font-weight: 500;
}
.p-1 {
  padding: 0.5rem;
}
.p-2 {
  padding: 1rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.ml-4 {
  margin-left: 3rem;
}
.ml-0 {
  margin-left: 0px;
}

.ml-auto {
  margin-left: auto;
}

.text-center {
  text-align: center;
}

.mt-5 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-100px {
  margin-bottom: 100px;
}

.mb-50px {
  margin-bottom: 50px;
}
.mr-2 {
  margin-right: 1rem;
}
.mr-30 {
  margin-right: 2%;
}
.ml-30 {
  margin-left: 2%;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-5 {
  padding-top: 3rem;
}

.p-5 {
  padding: 3rem;
}
.border-radius-14px {
  border-radius: 14px;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}

.bg-grey {
  background: #f5f5f7 !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #1764d8;
}

.bg-primary {
  background-color: #1764d8 !important;
}

.first_section {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.section_2-3 {
  flex-basis: 66.66%;
}

.section_1-3 {
  flex-basis: 33.33%;
}

.first_section_text_div {
  padding: 100px 150px 100px 200px;
  position: relative;
}

.first_section_illustration_div {
  height: 100%;
}

.img-center {
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.heading_left_horizontal {
  position: absolute;
  content: "";
  width: 129px;
  height: 39px;
  left: 0;
  background: #ffde9d;
  border-radius: 0px 10px 10px 0px;
}

.heading_left_vertical {
  position: absolute;
  content: "";
  height: 129px;
  width: 39px;
  left: 0;
  top: 0px;
  background: #ffde9d;
  border-radius: 20px 4px 4px 4px;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.section1-2 {
  flex-basis: 50%;
}
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.align-items-center {
  align-items: center;
}

.p-120px {
  padding: 120px;
}

.p-80px{
  padding: 30px;
}

.px-200px {
  padding-left: 200px;
  padding-right: 200px;
}
.px-120px {
  padding-left: 7.6%;
  padding-right: 7.6%;
}
.p-50px {
  padding: 50px;
}

.pt-2 {
  padding-top: 1rem;
}

.p-relative {
  position: relative;
}

.bg-yellow {
  background: #ebcf76;
}
.bg-green {
  background: #46d082;
}

.w-25 {
  width: 25%;
}

.w-30{
  width: 30%;
}

.w-35 {
  width: 35%;
}
.w-60 {
  width: 60%;
}

.float-end {
  float: right;
}

.employee_onboarding__text {
  padding: 10.41%;
  padding-left: 13.8%;
}

.employee_onboarding__div {
  background: #fffaed;
  position: relative;
  border-radius: 20px;
}

/* .employee_onboarding__div::after {
  position: absolute;
  content: "";
  width: 154px;
  height: 35px;
  left: 0px;
  top: 0px;
  background: #ffeab8;
  border-radius: 13.6215px;
} */

.rectangle_type_3 {
  position: absolute;
  width: 53px;
  height: 137px;
  left: 0px;
  top: 0px;
  background: #ffde9d;
  border-radius: 4.17808px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 90px;
  right: 16px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}

.min-h-320px {
  min-height: 360px;
}

.h-30px {
  height: 30px;
}
.pb-3 {
  padding-bottom: 1.5rem;
}
.p-33px {
  padding: 33px;
}
.px-33px {
  margin-left: 33px;
  margin-right: 33px;
}
.font-size-107px {
  font-size: 107px;
}
.bg-orange {
  background-color: #e5794c;
}
.p-4 {
  padding: 1.5rem;
}

.bg-light-brown {
  background: #fff4ec;
}

.border-radius-20 {
  border-radius: 20px;
}

.px-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.mt-50px {
  margin-top: 3.47%;
}
.mt-100px {
  margin-top: 6.9%;
}

.mx-50px {
  margin-left: 3.47%;
  margin-right: 3.47%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}

.color-light-blue {
  color: #5cbfd2;
}

.task_management_ul {
  list-style: none;
}

.task_management_ul li {
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-bottom: 1px solid #b6b6b6;
  font-weight: 600;
  font-size: 20px;
  color: #696969;
  transition: all 0.2s linear;
  position: relative;
}

.task_management_ul li:hover {
  /* color: #5cbfd2; */
  border-bottom: 1px solid #5cbfd2;
}

.task_management_ul li:hover:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 18px;
  left: -50px;
  top: 0px;
  background: #96e0e5;
  border-radius: 10px 0px 0px 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.color-light-grey {
  color: #7b7b7b;
}

.color-pink {
  color: #d44161;
}

.bg-pink {
  background-color: #f37d92 !important;
}

.color-green {
  color: #46d082;
}

.blog-grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 2.8%;
  row-gap: 60px;
}

.blog_page_banner {
  min-height: 70vh;
  position: relative;
}
.blog_page_banner div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  max-width: 60%;
}

.blog_page_banner h1 {
  color: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
}

.pricing_banner_section {
  min-height: 200px;
  position: relative;
}

.princing_banner_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.princing_banner_text h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 12px;
  color: #1d1d1f;
}
.h-auto {
  height: auto !important;
}
.princing_banner_text p {
  margin-bottom: 70px;
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  color: #7b7b7b;
}
.align-items-stretch {
  align-items: stretch;
}
.justify-content-center {
  justify-content: center;
}

.about_us_section {
  background-color: #1764d8;
  position: relative;
  min-height: 500px;
}
.about_us_section_text {
  text-align: center;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}

.about_us_section_text h1 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
}

.about_us_section_text p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.about_us_product_card {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 64px;
  padding-bottom: 70px;
}

.about_us_product_card_div {
  grid-row-gap: 60px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
  column-gap: 30px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 60px;
}
.about_us_product_card a {
  padding-bottom: 5px;
  border-bottom: 1px solid #7b7b7b;
}

.section_our_team_div {
  display: grid;
  grid-template-columns: auto auto auto auto;
  row-gap: 60px;
  column-gap: 30px;
}

.super_charge_payroll_home_card_div {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  -webkit-column-gap: 50px;
  column-gap: 50px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 50px;
}

.super_charge_payroll_home_card_div div {
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8.78019px);
  background: transparent;
  border-radius: 21.9505px;
}
.d-inline-block {
  display: inline-block;
}
.color-purple {
  color: #7d5ce4;
}
.d-grid-50-50 {
  grid-column-gap: 30px;
    grid-row-gap: 60px;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    display: grid;
    row-gap: 60px;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
}
.d-grid-40-60 {
  display: grid;
  grid-template-columns: calc(40% - 15px) calc(60% - 15px);
  column-gap: 30px;
  row-gap: 60px;
}
.d-grid-33-66 {
  grid-row-gap: 60px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    display: grid;
    grid-template-columns: calc(33.33% - 15px) calc(66.66% - 15px);
    row-gap: 60px;
}
.d-grid-66-33 {
  display: grid;
  grid-template-columns: calc(66.66% - 15px) calc(33.33% - 15px);
  row-gap: 60px;
  column-gap: 30px;
}

.d-grid-33-33-33 {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 60px;
  column-gap: 30px;
}
.d-grid-25-25-25-25 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 30px;
}
.text-bold {
  font-weight: 600;
}
#contact_sales_form,
#contact_partner_form {
  margin-top: 65px;
}

#contact_sales_form button,
#contact_partner_form button {
  margin-top: 48px;
}
#contact_sales_form input,
#contact_partner_form input {
  margin-bottom: 48px;
  margin-right: 60px;
  border: 0px;
  border-bottom: 1px solid #7b7b7b;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

#contact_sales_form input::placeholder,
#contact_partner_form input::placeholder {
  color: #b9b9b9;
}
#contact_sales_form input:focus,
#contact_partner_form input:focus {
  border-bottom: 1px solid #7b7b7b;
  outline: none;
}
.home_page_banner_text {
  min-height: 500px;
  background-color: #fff;
  position: relative;
  z-index: -1;
}

.justify-content-flex-end {
  justify-content: flex-end;
}
.home_page_banner_text_div {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.home_page_banner_text h1 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  margin-bottom: 32px;
}

.home_page_banner_text h1 span {
  font-family: Inter;
  font-size: 64px;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
}

.why_us_home_icons {
  padding: 5px;
  color: white;
  background-color: #1764d8;
  border-radius: 5px;
  font-size: 35px;
}

.home_page_banner_text h3 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}
.client_logo_slider .swiper-wrapper .swiper-slider {
  height: auto !important;
  border: 1.6495px solid #d5d5d5 !important;
}
.home_page_banner_image {
  margin-left: 165px;
  margin-right: 165px;
  margin-top: -220px;
}
.home_page_banner_image img {
  width: 100%;
  z-index: 999999000;
}
.super_charge_payroll_home_card {
  padding: 140px 200px;
}

.hover-brown-color:hover {
  color: #e5794c !important;
}
.hover-yellow-color:hover {
  color: #ecc054 !important;
}
.hover-light-blue-color:hover {
  color: #5cbfd2 !important;
}
.hover-pink-color:hover {
  color: #d44161 !important;
}
.hover-green-color:hover {
  color: #46d082 !important;
}
.hover-grey-color:hover {
  color: #696969 !important;
}

.hover-blue-color:hover {
  color: #1764d8 !important;
}

.hover-brown:hover {
  background-color: #e5794c;
}
.hover-yellow:hover {
  background-color: #ecc054;
}
.hover-light-blue:hover {
  background-color: #5cbfd2;
}
.hover-pink:hover {
  background-color: #d44161;
}
.hover-green:hover {
  background-color: #46d082;
}
.hover-grey:hover {
  background-color: #696969;
}
.swiper-slide {
  height: auto !important;
}

.swiper-wrapper {
  align-items: center !important;
}

.h-58px {
  height: 58px;
}
.h-28px {
  height: 28px;
}

.max-w-1600px {
  max-width: 1600px;
}

.p-100 {
  padding: 100px;
}

@media screen and (min-width: 1600px) {
  .blog-grid {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .max-w-1600px {
    margin-left: auto;
    margin-right: auto;
  }
}

.w-50D{
  width: 50%;
}

@media screen and (min-width: 2000px) {
  .blog-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
}

@media screen and (max-width: 992px) {
  .d-sm-padding-30px {
    padding: 30px;
}
.m-mt190 {
  margin-top: -190px !important;
}
.mb-sm-3 {
  margin-bottom: 1.5rem;
}
.p-sm-0 {
  padding: 0;
}
.m-opx{
  margin-top: 0px !important;
}
.d-sm-block {
  display: block;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.p-100 {
  padding: 30px!important;
}
.d-grid-33-66{
  grid-template-columns: 100%!important;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.w-sm-100 {
  width: 100%;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.w-sm-100 {
  width: 100%;
}
.mt-sm-60px {
  margin-top: 60px;
}
.d-sm-block {
  display: block;
}
.mb-sm-0 {
  margin-bottom: 0;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.d-grid-50-50 {
  display: block;
}
.mb-sm-0 {
  margin-bottom: 0;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.mt-sm-3 {
  margin-top: 1.5rem;
}
.d-grid-33-33-33 {
  grid-template-columns: auto;
  padding: 0;
}
.first_section_text_div {
  padding: 30px;
  position: relative;
}
.mt-sm-60px {
  margin-top: 60px;
}
.d-sm-block {
  display: block;
}
.img-center {
  left: 50%;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.pt-sm-2rem {
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mb-sm-60 {
  margin-bottom: 60px;
}
.mx-sm-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}
.w-sm-85 {
  width: 85%;
}
.w-sm-100 {
  width: 100%;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.w-sm-100 {
  width: 100%;
}
.d-grid-66-33 {
  grid-template-columns: 100%;
  margin-bottom: 10%;
}
.mt-sm-60px {
  margin-top: 60px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.padding_mobile_1rem {
  padding: 1rem;
}
.leavemng_tna {
  display: block;
}
.mt-sm-130px {
  margin-top: 130px;
}
.button_home_primary {
  margin-bottom: 20px;
  min-width: 305px;
}
.button_home_alternative {
  margin: 0;
  min-width: 305px;
}
.home_page_banner_image {
  margin-left: 20px;
  margin-right: 20px;
}
 .super_charge_payroll_home_card_div div {
  padding: 30px;
}
.super_charge_payroll_home_card, .super_charge_payroll_small_card {
  padding: 30px;
}
.super_charge_payroll_home_card_div {
  grid-template: none;
}
.d-grid-25-25-25-25, .d-grid-33-66 {
  grid-template-columns: 100%;
}
.d-grid-25-25-25-25 {
  padding: 30px;
  row-gap: 30px;
}
.text-sm-center {
  text-align: center;
}
.powering_teams {
  padding: 30px;
}
.home_page_banner_text_div h1 {
  justify-content: center;
}
.p-0px{
  padding: 0px;
}
.home_page_banner_image video
{
  margin: 125px 0px 0px 0px !important;
  width: 100% !important;

}
}

@media screen and (max-width: 768px){
  .d-sm-padding-30px {
    padding: 30px;
}
.w-50D{
  width: 100%;
}
.mb-sm-3 {
  margin-bottom: 1.5rem;
}
.p-sm-0 {
  padding: 0;
}
.d-sm-block {
  display: block;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.p-100 {
  padding: 30px!important;
}
.d-grid-33-66{
  grid-template-columns: 100%!important;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.w-sm-100 {
  width: 100%;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.w-sm-100 {
  width: 100%;
}
.mt-sm-60px {
  margin-top: 60px;
}
.d-sm-block {
  display: block;
}
.mb-sm-0 {
  margin-bottom: 0;
}
.p-0px{
  padding: 24px 0px 0px 0px !important;
}

.p-px{
  padding: 0px !important;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.d-grid-50-50 {
  display: block;
}
.mb-sm-0 {
  margin-bottom: 0;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.mt-sm-3 {
  margin-top: 1.5rem;
}
.d-grid-33-33-33 {
  grid-template-columns: auto;
  padding: 0;
}
.first_section_text_div {
  padding: 30px;
  position: relative;
}
.mt-sm-60px {
  margin-top: 60px;
}
.d-sm-block {
  display: block;
}
.img-center {
  left: 50%;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.pt-sm-2rem {
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mb-sm-60 {
  margin-bottom: 60px;
}
.mx-sm-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}
.w-sm-85 {
  width: 85%;
}
.w-sm-100 {
  width: 100%;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.w-sm-100 {
  width: 100%;
}
.d-grid-66-33 {
  grid-template-columns: 100%;
  margin-bottom: 10%;
}
.mt-sm-60px {
  margin-top: 60px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.padding_mobile_1rem {
  padding: 1rem;
  margin-top: 20px;
}
.leavemng_tna {
  display: block;
}
.mt-sm-130px {
  margin-top: 130px;
}
.button_home_primary {
  margin-bottom: 20px;
  min-width: 305px;
}
.button_home_alternative {
  margin-right: 30px;
  min-width: 305px;
}
.home_page_banner_image {
  margin-left: 20px;
  margin-right: 20px;
}
 .super_charge_payroll_home_card_div div {
  padding: 30px;
}
.super_charge_payroll_home_card, .super_charge_payroll_small_card {
  padding: 30px;
}
.super_charge_payroll_home_card_div {
  grid-template: none;
}
.d-grid-25-25-25-25, .d-grid-33-66 {
  grid-template-columns: 100%;
}
.d-grid-25-25-25-25 {
  padding: 30px;
  row-gap: 30px;
}
.text-sm-center {
  text-align: center;
}
.powering_teams {
  padding: 30px;
}
.home_page_banner_text_div h1 {
  justify-content: center;
}
.div_top_text_animation {
  display: inline-block;
  height: 35px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 60%;
}
.mt-sm-3 {
  margin-top: 1.5rem;
}
.d-sm-flex-direction-column {
  flex-direction: column;
}
.princing_banner_text .fontsizeh1{

  font-weight: 600;
  font-size: 24px;
  line-height: 58px;
  margin-bottom: 12px;
  color: #1d1d1f;
}
.texti{
  /* text-align: center !important; */
  margin: 0px !important;
}
.home_page_banner_text h1 span {
  font-family: Inter;
  font-size: 45px;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
}
.home_page_banner_image video
{
  margin: 125px 0px 0px 0px !important;
  width: 100% !important;

}
.landing_page_first__text {
  padding: 10%!important;
}
.landing_page_first_grid {
  display: block!important;
}
.landing_page_first_form {
  padding: 60px 30px!important;
}
.contact-form form input {
  margin-bottom: 15%!important;
  min-width: 100%;
}
.mr-sm-0px {
  margin-right: 0!important;
}
}



.div_top_text_animation {
  height: 70px;
  margin-left: 13px;
  width: 58%;
  text-align: left;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.scroller {
  height: 1.4em;
  line-height: 1.4em;
  overflow: hidden !important;
  width: auto;
}

.slide_from_top {
  -webkit-animation: slide_from_topAn 2s ease-in-out;
  animation: slide_from_topAn 2s ease-in-out;
  display: block;
  height: auto!important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.scroller > span {
  position: absolute;
  transform: all 2s;
  animation: slide 14s infinite;
  animation-delay: 2s;
  font-weight: bold;
}

.policy{
  min-height: 200px;
}

.button_home_alternative {
  border: 1px solid #1764d8;
  padding: 18px 77px;
  background-color: #fff;
  color: #1764d8;
  border-radius: 25px;
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  margin-left: 25px;
}
.button_home_primary {
  border: 1px solid #1764d8;
  padding: 18px 77px;
  background-color: #1764d8;
  color: #fff;
  border-radius: 25px;
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
}
@keyframes slide {
  0% {
    top: 0;
  }
  14.2% {
    top: -1.2em;
  }
  28.5% {
    top: -2.6em;
  }
  42.8% {
    top: -3.6em;
  }
  57.1% {
    top: -4.8em;
  }
  71.4% {
    top: -6em;
  }
  85.71% {
    top: -7.2em;
  }
  100% {
    top: 0;
  }
}

.box-shadow-img {
  box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.1);
}

.w-70 {
  width: 70%;
}

.landing_page_first_grid {
  min-height: 500px;
}

.landing_page_first_grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.landing_page_first__text {
  max-width: 800px;
  color: #fff;
  padding: 175px 110px;
}

.landing_page_first__text h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #fff;
}

.landing_page_first_form {
  padding: 145px 80px;
  background-color: #fff;
}

.client-slider-logo-div {
  height: 60px;
  width: 150px;
}

.landing_page_first_form input {
  margin-bottom: 61px;
  min-width: 245px;
  border: 0px;
  border-bottom: 1px solid #7b7b7b;
  padding-bottom: 10px;
  font-size: 20px;
}

.landing_page_first_form input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #c2c2c2;
}

.landing_page_first_form input:focus {
  border-bottom: 1px solid #7b7b7b;
  outline: none;
}
.mr-70px {
  margin-right: 70px;
}

.text-landing-2nd {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #696969;
}

.about_us_our_missione_left {
  font-family: Inter;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #1764d8;
  padding-bottom: 20px;
}

.text_404 {
  font-family: Inter;
  font-size: 250px;
  font-weight: 900;
  line-height: 303px;
  letter-spacing: 0em;
  text-align: left;
  /* color: transparent; */
  background: linear-gradient(
    103.4deg,
    rgba(255, 255, 255, 0.5) 16.39%,
    rgba(255, 255, 255, 0.1) 106.21%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}



@media screen and (max-width: 1380px) {
  .d-sm-padding-30px {
    padding: 30px;
}
.mb-sm-3 {
  margin-bottom: 1.5rem;
}
.p-sm-0 {
  padding: 0;
}
.d-sm-block {
  display: block;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.p-100 {
  padding: 30px!important;
}
.d-grid-33-66{
  grid-template-columns: 100%!important;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.w-sm-100 {
  width: 100%;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.w-sm-100 {
  width: 100%;
}
.mt-sm-60px {
  margin-top: 60px;
}
.d-sm-block {
  display: block;
}
.mb-sm-0 {
  margin-bottom: 0;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.d-grid-50-50 {
  display: block;
}
.mb-sm-0 {
  margin-bottom: 0;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.mt-sm-3 {
  margin-top: 1.5rem;
}
.d-grid-33-33-33 {
  grid-template-columns: auto;
  padding: 0;
}
.first_section_text_div {
  padding: 30px;
  position: relative;
}
.mt-sm-60px {
  margin-top: 60px;
}
.d-sm-block {
  display: block;
}
.img-center {
  left: 50%;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.pt-sm-2rem {
  padding-top: 2rem;
}
.px-sm-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.mb-sm-60 {
  margin-bottom: 60px;
}
.mx-sm-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}
.w-sm-85 {
  width: 85%;
}
.w-sm-100 {
  width: 100%;
}
.py-sm-2rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.w-sm-100 {
  width: 100%;
}
.d-grid-66-33 {
  grid-template-columns: 100%;
  margin-bottom: 10%;
}
.mt-sm-60px {
  margin-top: 60px;
}
.mt-sm-60px {
  margin-top: 60px;
}
.padding_mobile_1rem {
  padding: 1rem;
}
.leavemng_tna {
  display: block;
}
.mt-sm-130px {
  margin-top: 130px;
}
.button_home_primary {
  margin-bottom: 20px;
  min-width: 305px;
}
.button_home_alternative {
  margin: 0;
  min-width: 305px;
}
.home_page_banner_image {
  margin-left: 20px;
  margin-right: 20px;
}
 .super_charge_payroll_home_card_div div {
  padding: 30px;
}
.super_charge_payroll_home_card, .super_charge_payroll_small_card {
  padding: 30px;
}
.super_charge_payroll_home_card_div {
  grid-template: none;
}
.d-grid-25-25-25-25, .d-grid-33-66 {
  grid-template-columns: 100%;
}
.d-grid-25-25-25-25 {
  padding: 30px;
  row-gap: 30px;
}
.text-sm-center {
  text-align: center;
}
.powering_teams {
  padding: 30px;
}
.home_page_banner_text_div h1 {
  justify-content: center;
}
.p-0px{
  padding: 0px;
}
.home_page_banner_image video
{
  margin: 125px 0px 0px 0px !important;
  width: 100% !important;
  margin-top: 0px !important;

}
}
