.testimonial_client_img {
  height: 133px;
  border-radius: 20px;
}

.client_details {
  display: inline-block;
  height: 100%;
  margin-left: 30px;
}

.client_name {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
}
.designation {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.testimonial_text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
