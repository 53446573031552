.newsletter_button {
  background: #1764d8;
  border: 0.5px solid #1764d8;
  border-radius: 20px;
  color: white;
  padding: 20px 69px;
  font-weight: 700;
  font-size: 20px;
  margin-left: -60px;
}

.form_div {
  margin: 25px auto;
}

.form_div input {
  background: #ffffff;
  border: 1px solid #1764d8;
  border-radius: 20px;
  width: 594px;
  height: 62px;
  font-size: 16px;
  padding-left: 25px;
}

.form_div p {
  margin-top: 25px;
}

.form_div input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  background-color: #fff;
  color: #c2c2c2;
}

.form_div input:focus {
  border: 1px solid #1764d8 !important;
}


