.blog_categories_component {
  position: relative;
}

.blog_categories_ul {
  padding: 42px 0px;
  padding-left: 183px;
  list-style: none;
  display: flex;
  overflow-x: scroll;
  position: relative;
  white-space: nowrap;
}

.blog_categories_ul::-webkit-scrollbar {
  display: none;
}

.blog_category {
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #b9b9b9;
  margin-right: 80px;
  padding-bottom: 13px;
}

.active, .blog_category:hover {
  color: #1d1d1f;
  border-bottom: 3px solid #7b7b7b;
}

.blog_search_div {
  position: absolute;
  right: 0;
  top: 0px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 20px 20px;
  color: #1764d8;
  height: 100%;
  margin: auto;
  background-color: #fff;
  transition: all 10.5s linear;

  /* width: 100%; */
}

.blog_search_div input {
  font-size: 24px;
  /* display: none; */
  width: 93%;
  height: 100%;
  margin-right: 50px;
  border-radius: 20px;
  padding: 30px;
  position: absolute;
  border: 1px solid #1764d8;
  transition: all 10.5s linear;
}

.blog_search_icon_div {
  float: right;
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 44px 60px;
  z-index: 999;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
}

.blog_search_icon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 36px;
  height: 36px;
}

.blog_search_div input:focus {
  outline: none !important;
}

.blog_search_div input::placeholder {
  color: #1764d8;
}
